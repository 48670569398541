<template>
  <div class="addMonitorAreaSetting">
    <div class="exit" @click="returnBtn">
      <i class="iconfont its_ba_fanhui"></i>
    </div>
    <h2>监测区域</h2>
    <div class="explain">
      <header><i class="el-icon-warning"></i> 数据说明</header>
      <ol>
        <li>高速路线请在高速路段设置监测区域</li>
        <li>非高速线路请在途中可能上车的位置后或临近目的地前设置监测区域。</li>
        <li>监测区域至少添加2个，最多添加5个</li>
        <li>监测区域请避开隧道，避免信号差、光线暗影响监测效果</li>
      </ol>
    </div>
    <el-tabs v-model="active" @tab-click="handleClick">
      <el-tab-pane
        v-for="(item, index) in list"
        :key="index"
        :name="item.stationName"
        :label="item.stationName"
      ></el-tab-pane>
    </el-tabs>
    <el-button
      type="primary"
      size="small"
      :disabled="tableData.length >= 5 || index == list.length - 1"
      @click="addArea"
      >+ 添加监测区域</el-button
    >
    <Table
      :title-name="titleName"
      :table-data="tableData"
      :operation="true"
      :empty-text="
        index == list.length - 1 ? '终点站无需配置监测区域' : '暂无数据'
      "
      :col-class-type="true"
      :operation-width="150"
    >
      <template slot-scope="{ scopeRow }">
        <el-button
          type="text"
          size="small"
          sort="primary"
          style="margin-right: 20px"
          @click="editBtn(scopeRow)"
          >编辑</el-button
        >
        <el-button
          type="text"
          size="small"
          sort="denger"
          @click="deleteBtn(scopeRow)"
          >删除</el-button
        >
      </template>
    </Table>
    <el-dialog
      v-if="show"
      :title="title"
      :visible.sync="show"
      :close-on-click-modal="false"
      @close="onClose"
    >
      <el-form ref="addForm" :model="addForm">
        <el-form-item
          label="区域名称："
          prop="name"
          :rules="{
            required: true,
            message: '请输入区域名称',
            trigger: 'blur',
          }"
        >
          <el-input
            v-model="addForm.name"
            placeholder="请输入区域名称"
            style="width: 200px"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="map">
        <edit-map
          ref="editmap"
          id="id"
          :station="station"
          :area-content="addFormCopy.addressInfo"
          :add-form2-copy="addFormCopy"
          @areaContent="areaContentFUN"
          @changeAreaContent="changeAreaContent"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="onClose">取消</el-button>
        <el-button type="primary" size="small" @click="onSave">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import editMap from "../vehicleManage/editMap/editMap.vue";
import {
  queryPassengerDetectionSettingInfo,
  findAreaSettingBySettingId,
  addAreaSetting,
  updateAreaSetting,
  delAreaSetting,
  queryLineList,
  queryIntercityMainLinePageAPI,
  getCityCodeAPI,
} from "@/api/lib/api.js";
export default {
  components: { editMap },
  data() {
    return {
      id: null,
      active: null,
      index: 0,
      list: [],
      tableData: [],
      titleName: [
        {
          title: "名称",
          props: "name",
        },
      ],
      show: false,
      addForm: {
        passengerDetectionSettingId: null,
        name: "",
        startStationId: null,
        endStationId: null,
        startStation: "",
        endStation: "",
        addressInfo: "",
      },
      addFormCopy: {
        addressInfo: "",
      },
      areaContentData: null,
      areas: [],
      title: "",
      region: [],
      station: "",
      cityList: [],
      middleList: [],
    };
  },
  created() {
    this.id = parseInt(this.$route.query.id);
    this.queryStations();
  },
  methods: {
    returnBtn() {
      this.$router.go(-1);
    },
    getCityCode(arr) {
      getCityCodeAPI().then((res) => {
        if (res.code === "SUCCESS") {
          this.cityList = res.data[0].districts;
          arr.forEach((e) => {
            const codes = e.intercityStationVO.parentAdCode;
            const list1 = this.cityList.find(
              (i) => i.adcode == codes[0]
            ).districts;
            const list2 = list1.find((i) => i.adcode == codes[1]).districts;
            this.region.push(list2.find((i) => i.adcode == codes[2]).name);
            this.station = this.region[this.index];
            this.show = true;
          });
        }
      });
    },
    queryStations() {
      const id = this.$route.query.lineId;
      queryLineList({ id }).then((res) => {
        if (res.code == "SUCCESS") {
          const data = {
            pageSize: 10,
            currentPage: 1,
            lineCode: res.data.mainLineCode,
          };
          queryIntercityMainLinePageAPI(data).then((res2) => {
            if (res2.code == "SUCCESS") {
              const obj = res2.data.list[0];
              const start =
                obj.startCityName.split(",")[obj.startCityCodes.length - 1];
              this.region.push(start);
              this.station = start;
              this.queryInfo();
            }
          });
        }
      });
    },
    queryInfo() {
      queryPassengerDetectionSettingInfo({ id: this.id }).then((res) => {
        if (res.code == "SUCCESS") {
          this.list = res.data.lineStationVOS;
          this.active = this.list[0].stationName;
          this.middleList = res.data.lineStationVOS.filter(
            (i) => i.intercityStationVO
          );
          this.queryTableData();
        }
      });
    },
    queryTableData() {
      findAreaSettingBySettingId({ id: this.id }).then((res) => {
        if (res.code == "SUCCESS") {
          this.areas = res.data;
          this.active = this.list[this.index].stationName;
          this.tableData = this.areas.filter(
            (item) => item.startStationName == this.active
          );
        }
      });
    },
    handleClick(tab) {
      this.index = parseInt(tab.index);
      this.addFormCopy = {
        addressInfo: "",
      };
      this.addForm = {
        passengerDetectionSettingId: null,
        name: "",
        startStationId: null,
        endStationId: null,
        startStation: "",
        endStation: "",
        addressInfo: "",
      };
      if (this.index == this.list.length - 1) {
        this.tableData = [];
        return;
      }
      this.tableData = this.areas.filter(
        (item) => item.startStationName == this.active
      );
    },
    addArea() {
      this.title = "添加区域设置";
      if (this.index) this.getCityCode(this.middleList);
      else this.show = true;
    },
    editBtn(row) {
      this.addFormCopy = JSON.parse(JSON.stringify(row));
      this.addForm.startStationId = row.startStationId;
      this.addForm.startStation = row.startStationName;
      this.addForm.endStationId = row.endStationId;
      this.addForm.endStation = row.endStationName;
      this.addForm.id = row.id;
      this.addForm.name = row.name;
      this.title = "编辑区域设置";
      if (this.index) this.getCityCode(this.middleList);
      else this.show = true;
    },
    deleteBtn(row) {
      this.$confirm("确认删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delAreaSetting({ areaSettingId: row.id }).then((res) => {
          if (res.code == "SUCCESS") {
            this.show = false;
            this.$message.success("删除成功");
            this.queryTableData();
          }
        });
      });
    },
    areaContentFUN() {
      this.areaContentData = null;
      this.addForm.addressInfo = "";
    },
    changeAreaContent(obj) {
      this.areaContentData = JSON.stringify(obj.path);
    },
    onClose() {
      this.show = false;
      this.areaContentData = null;
      this.addFormCopy = {
        addressInfo: "",
      };
      this.addForm = {
        passengerDetectionSettingId: null,
        name: "",
        startStationId: null,
        endStationId: null,
        startStation: "",
        endStation: "",
        addressInfo: "",
      };
    },
    onSave() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          if (!this.areaContentData) {
            this.$message.warning("请先规划区域");
            return;
          }
          this.addForm.passengerDetectionSettingId = this.id;
          this.addForm.addressInfo = this.areaContentData;
          if (this.title == "添加区域设置") {
            this.addForm.startStationId = this.list[this.index].stationId;
            this.addForm.startStation = this.list[this.index].stationName;
            this.addForm.endStationId = this.list[this.index + 1].stationId;
            this.addForm.endStation = this.list[this.index + 1].stationName;
            addAreaSetting(this.addForm).then((res) => {
              if (res.code == "SUCCESS") {
                this.onClose();
                this.$message.success("添加成功");
                this.queryTableData();
              }
            });
          } else {
            updateAreaSetting(this.addForm).then((res) => {
              if (res.code == "SUCCESS") {
                this.onClose();
                this.$message.success("编辑成功");
                delete this.addForm.id;
                this.queryTableData();
              }
            });
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.addMonitorAreaSetting {
  background-color: #fff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  .exit {
    width: 32px;
    height: 32px;
    color: #333333;
    font-size: 15px;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.1);

    i {
      cursor: pointer;
    }
  }
  .explain {
    color: #666666;
    font-size: 14px;
    background-color: #e4ecff;
    border-radius: 6px;
    padding: 16px;
    box-sizing: border-box;
    margin: 16px 0;
    header {
      font-weight: bold;
      .el-icon-warning {
        color: #336ffe;
        font-size: 17px;
      }
    }
    ol {
      margin-bottom: 0;
      li {
        margin-bottom: 8px;
      }
    }
  }
  .Table {
    margin-top: 16px;
  }
  .map {
    height: 500px;
  }
}
</style>
