var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "addMonitorAreaSetting" },
    [
      _c("div", { staticClass: "exit", on: { click: _vm.returnBtn } }, [
        _c("i", { staticClass: "iconfont its_ba_fanhui" }),
      ]),
      _c("h2", [_vm._v("监测区域")]),
      _vm._m(0),
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.active,
            callback: function ($$v) {
              _vm.active = $$v
            },
            expression: "active",
          },
        },
        _vm._l(_vm.list, function (item, index) {
          return _c("el-tab-pane", {
            key: index,
            attrs: { name: item.stationName, label: item.stationName },
          })
        }),
        1
      ),
      _c(
        "el-button",
        {
          attrs: {
            type: "primary",
            size: "small",
            disabled:
              _vm.tableData.length >= 5 || _vm.index == _vm.list.length - 1,
          },
          on: { click: _vm.addArea },
        },
        [_vm._v("+ 添加监测区域")]
      ),
      _c("Table", {
        attrs: {
          "title-name": _vm.titleName,
          "table-data": _vm.tableData,
          operation: true,
          "empty-text":
            _vm.index == _vm.list.length - 1
              ? "终点站无需配置监测区域"
              : "暂无数据",
          "col-class-type": true,
          "operation-width": 150,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ scopeRow }) {
              return [
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-right": "20px" },
                    attrs: { type: "text", size: "small", sort: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.editBtn(scopeRow)
                      },
                    },
                  },
                  [_vm._v("编辑")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text", size: "small", sort: "denger" },
                    on: {
                      click: function ($event) {
                        return _vm.deleteBtn(scopeRow)
                      },
                    },
                  },
                  [_vm._v("删除")]
                ),
              ]
            },
          },
        ]),
      }),
      _vm.show
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title,
                visible: _vm.show,
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.show = $event
                },
                close: _vm.onClose,
              },
            },
            [
              _c(
                "el-form",
                { ref: "addForm", attrs: { model: _vm.addForm } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "区域名称：",
                        prop: "name",
                        rules: {
                          required: true,
                          message: "请输入区域名称",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入区域名称" },
                        model: {
                          value: _vm.addForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "name", $$v)
                          },
                          expression: "addForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "map" },
                [
                  _c("edit-map", {
                    ref: "editmap",
                    attrs: {
                      id: "id",
                      station: _vm.station,
                      "area-content": _vm.addFormCopy.addressInfo,
                      "add-form2-copy": _vm.addFormCopy,
                    },
                    on: {
                      areaContent: _vm.areaContentFUN,
                      changeAreaContent: _vm.changeAreaContent,
                    },
                  }),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: { click: _vm.onClose },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.onSave },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "explain" }, [
      _c("header", [
        _c("i", { staticClass: "el-icon-warning" }),
        _vm._v(" 数据说明"),
      ]),
      _c("ol", [
        _c("li", [_vm._v("高速路线请在高速路段设置监测区域")]),
        _c("li", [
          _vm._v(
            "非高速线路请在途中可能上车的位置后或临近目的地前设置监测区域。"
          ),
        ]),
        _c("li", [_vm._v("监测区域至少添加2个，最多添加5个")]),
        _c("li", [
          _vm._v("监测区域请避开隧道，避免信号差、光线暗影响监测效果"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }